import { PropsWithChildren, ReactNode } from "react";
import { Container } from "react-bootstrap";
import { LangSwitcher } from "../LangSwitcher";

interface Props {
  title?: string | ReactNode;
  titleComponent?: string | ReactNode;
  left?: string | ReactNode;
}

function Title({ children }: PropsWithChildren<{}>) {
  return (
    <div className="d-flex justify-content-around">
      <h1 className="fw-light mb-0">{children}</h1>
    </div>
  );
}

export default function Header({
  title,
  titleComponent,
  left,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Container
      className="py-3 bg-surface-variant shadow text-on-surface-variant text-center"
      style={{ minHeight: 80 }}
      fluid
    >
      <div className="d-flex align-items-center">
        <div style={{ width: "200px" }}>{left}</div>
        <div className="flex-grow-1">
          {title && <Title>{title}</Title>}
          {titleComponent && titleComponent}
        </div>
        <div
          className="text-end me-2 align-self-start"
          style={{ width: "200px" }}
        >
          <LangSwitcher />
        </div>
      </div>

      {children}
    </Container>
  );
}
