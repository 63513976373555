import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useGetLocker from "../../hooks/useGetLocker";

interface Props {
  show: boolean;
  onHide: () => any;
  code: string;
}

export function ConfirmAddressHelpModal({ show, onHide, code }: Props) {
  const { t } = useTranslation();
  const locker = useGetLocker({ refetchOnMount: false });
  const location = locker.data?.location;

  return (
    <Modal show={show} onHide={onHide} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="bi-question-circle me-2"></i>
          {t("common.help")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          <strong>{t("faq.confirmAddress.title")}</strong>
        </p>

        <p>{t("faq.confirmAddress.description")}</p>

        <p>
          {t("faq.confirmAddress.code")}: <strong>{code}</strong> <br />
          {t("faq.confirmAddress.address")}:{" "}
          <strong>
            {location?.street1} {location?.street2} {location?.city} (
            {location?.zip_code})
          </strong>
        </p>

        <p>{t("faq.confirmAddress.description2")}</p>

        <p>{t("faq.confirmAddress.description3")}</p>
      </Modal.Body>
    </Modal>
  );
}
