import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Button } from "react-bootstrap";
import { ReactElement } from "react";

interface Props {
  left?: ReactElement;
  children?: React.ReactNode;
}

const Footer = ({ left, children }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container
      fluid
      className="position-absolute bottom-0 px-5 pb-3 border-bottom border-5 border-surface-variant"
    >
      <Row>
        <Col className="d-flex align-items-center">{left}</Col>
        <Col className="text-center">{children}</Col>
        <Col className="text-end">
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate("/help")}
          >
            {t("footer.help")}
            <i className="bi-question-circle-fill ms-2"></i>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
