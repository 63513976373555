import React from "react";
import Lottie from "lottie-react";
import animationData from "./check-animated.json";

const GreenCheckAnimation = () => {
  return (
    <Lottie
      animationData={animationData}
      loop={false}
      autoplay={true}
      style={{ height: 320, width: 320 }}
    />
  );
};

export default GreenCheckAnimation;
