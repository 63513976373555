import { useEffect, useState } from "react";

export default function useOnTimeout(timeout: number, callback: () => any) {
  const [counter, setCounter] = useState(timeout);
  const [callbackCalled, setCallbackCalled] = useState(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (counter > 0) {
      timeout = setTimeout(() => setCounter(counter - 1), 1000);
    } else if (!callbackCalled) {
      callback();
      setCallbackCalled(true);
    }

    return () => clearTimeout(timeout);
  }, [counter, callback, callbackCalled]);

  return counter;
}
