import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import Keyboard from "react-simple-keyboard";
import { Footer } from "../../components/Footer";
import { Numpad } from "../../components/Numpad";
import RoundButton from "../../components/RoundButton";
import RecipientList from "../SelectRecipient/RecipientList";
import { Header } from "../../components/Header";
import { layout } from "../SelectRecipient/SelectRecipient";
import "react-simple-keyboard/build/css/index.css";
import useOnTimeout from "../../hooks/useOnTimeout";
import { sortRecipients } from "../../helpers/SortRecipientsHelper";

const AddRecipient = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [filter, setFilter] = useState("");
  const [showNumpad, setShowNumpad] = useState(true);
  const { state } = useLocation();
  const counter = useOnTimeout(45, () => navigate("/"));

  useEffect(() => {
    const cachedRecipients = (queryClient.getQueryData(["recipients"]) as Recipient[]) || [];
    const recipientList = sortRecipients(cachedRecipients, filter, showNumpad);
    const selectedRecipientIds = new Set(state.recipients.map((recipient: Recipient) => recipient.id));
    const filteredRecipients = recipientList.filter((recipient) => !selectedRecipientIds.has(recipient.id));

    setRecipients(filteredRecipients);
  }, [state.recipients, filter, queryClient, showNumpad]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("recipients.add")} />

      <Container className="mt-4 pt-2">
        <Row className="align-items-center gy-3">
          <Col>
            <div className="shadow rounded bg-surface text-on-surface">
              <div
                className="fs-4 fw-light rounded-top"
                style={{
                  minHeight: showNumpad ? 500 : 250,
                  maxHeight: showNumpad ? 500 : 250,
                  overflow: "overlay",
                }}
              >
                <RecipientList
                  recipients={recipients}
                  showEmail={false}
                  onSelect={(recipient: Recipient) =>
                    navigate("/recipients-confirmation", {
                      state: {
                        recipients: [...state.recipients, recipient],
                        door_position: state.door_position,
                        responses: state.responses
                      },
                    })
                  }
                />
              </div>
            </div>
          </Col>

          {showNumpad && (
            <Col md="5">
              <Numpad
                className="shadow py-4 rounded bg-surface bg-gradient"
                placeholder={t("recipients.unit") || ""}
                onChange={(code) => setFilter(code)}
                extra={
                  <RoundButton
                    variant="secondary"
                    onPointerUp={() => {
                      setShowNumpad(false);
                      setFilter("");
                    }}
                  >
                    ABC
                  </RoundButton>
                }
              />
            </Col>
          )}

          {!showNumpad && (
            <Col md="12">
              <div className="p-2 shadow rounded bg-surface bg-gradient">
                <input
                  type="text"
                  className="form-control form-control-lg mb-2"
                  placeholder={t("recipients.filter") || ""}
                  readOnly
                  value={filter}
                />
                <Keyboard
                  layout={layout}
                  onChange={(code: string) => setFilter(code)}
                  display={{ "{shift}": "123" }}
                  mergeDisplay={true}
                  onKeyPress={(button: string) => {
                    if (button === "{shift}") {
                      setShowNumpad(true);
                      setFilter("");
                    }
                  }}
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate(-1)}
          >
            <i className="bi-arrow-left-circle-fill me-2"></i>
            {t("common.back")} ({counter})
          </Button>
        }
      />
    </div>
  );
};

export default AddRecipient;
