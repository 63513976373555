import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { useCamera } from "components/Camera";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { useToast } from "../../components/Toast";
import { getConfig } from "../../config";
import useCreateBulkReservation from "../../hooks/useCreateBulkReservation";
import useOnTimeout from "../../hooks/useOnTimeout";
import useOpenDoor from "../../hooks/useOpenDoor";
import useReservationCache from "../../hooks/useReservationCache";

const RecipientsConfirmation = () => {
  const [recipientList, setRecipientList] = useState([]);
  const { lockerId } = getConfig();
  const { warn } = useToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const openDoor = useOpenDoor();
  const lockerQuery: Locker | undefined = queryClient.getQueryData([
    "locker",
    lockerId,
  ]);
  const reservationCache = useReservationCache();
  const { state } = useLocation();
  const counter = useOnTimeout(45, () => navigate("/"));
  const camera = useCamera();

  const createBulkReservation = useCreateBulkReservation({
    onSuccess: (data) => {
      const { reservations } = data as BulkReservation;
      if (reservations && state.door_position) {
        queryClient.invalidateQueries({ queryKey: ["locker"] });
        openDoor.mutate(state.door_position);
        reservationCache.clear();
        navigate("/deposit-success", { state: { reservations } });
      } else {
        Sentry.captureMessage(
          "No reservations returned from createBulkReservation"
        );
        warn();
      }
    },
  });

  const createBulkReservationHandler = (recipientList: Array<Recipient>) => {
    let deposit_photo: string | undefined;
    if (lockerQuery && lockerQuery?.config?.webcam_enabled) {
      deposit_photo = camera?.capture() || undefined;
    }

    const recipientReservationList: Reservation[] = recipientList.map(
      (recipient: Recipient & { tracking_number?: string }) => ({
        recipient_id: recipient.id,
        first_name: recipient.first_name,
        last_name: recipient.last_name,
        email: recipient.email,
        cellphone_number: recipient.mobile_number,
        door_position: state.door_position,
        locker_id: lockerId,
        deposit: true,
        deposit_answers: state.responses || undefined,
        tracking_number: recipient?.tracking_number || undefined,
        unit: recipient?.unit || undefined,
      })
    );

    createBulkReservation.mutate({
      deposit_photo,
      reservations: recipientReservationList,
    });
  };

  useEffect(() => {
    setRecipientList(
      state.recipients.sort((a: Recipient, b: Recipient) => {
        const searchA = `${a.unit} ${a.first_name} ${a.last_name}`;
        const searchB = `${b.unit} ${b.first_name} ${b.last_name}`;
        return searchA.localeCompare(searchB);
      })
    );
  }, [state.recipients]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0 overflow-hidden">
      <Header title={t("confirmRecipients.title")} />
      <h1 className="fw-light fs-3 text-center my-5">
        {t("confirmRecipients.subtitle")}
      </h1>
      <div
        className="shadow rounded bg-surface text-on-surface m-auto"
        style={{ width: 750 }}>
        <div
          className="fs-4 fw-light rounded-top"
          style={{
            minHeight: 250,
            maxHeight: 250,
            overflow: "overlay",
          }}>
          <Table className="table-surface">
            <tbody>
              {recipientList.length === 0 ? (
                <tr>
                  <td className="d-flex justify-content-center px-4">
                    {t("confirmRecipients.addRecipient")}
                  </td>
                </tr>
              ) : (
                recipientList?.map((r: Recipient, i) => (
                  <tr key={r.id || i}>
                    <td className="d-flex justify-content-between px-4">
                      {`${r.first_name || ""} ${r.last_name || ""}`}{" "}
                      {r.unit && `#${r.unit}`} {!r.id && r.mobile_number}
                      <Button
                        onPointerUp={(e) => {
                          recipientList.splice(i, 1);
                          setRecipientList(recipientList);
                        }}
                        className="btn btn-danger">
                        {t("confirmRecipients.remove")}
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <div
        className="d-flex justify-content-end align-items-center mx-auto my-5 bg-white text-black rounded-pill shadow gap-4"
        role="button"
        style={{ width: 500, height: 60 }}
        onPointerUp={() =>
          navigate("/add-recipient", {
            state: {
              recipients: recipientList ? recipientList : state.recipients,
              door_position: state.door_position,
              responses: state.responses,
            },
          })
        }>
        {t("confirmRecipients.deposit")}
        <Button
          size="lg"
          className="rounded-pill d-flex gap-2 align-items-center"
          style={{ height: 60 }}>
          <i className="bi bi-box-seam ms-1"></i>
          {t("confirmRecipients.addParcel")}
        </Button>
      </div>

      <Button
        size="lg"
        style={{ height: 60 }}
        className="d-flex mx-auto align-items-center"
        disabled={recipientList.length === 0 || createBulkReservation.isLoading}
        onPointerUp={() => createBulkReservationHandler(recipientList)}>
        {t("confirmRecipients.openDoor")}
      </Button>

      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate(state.next || -1)}>
            <i className="bi-arrow-left-circle-fill me-2"></i>
            {t("common.back")} ({counter})
          </Button>
        }
      />
    </div>
  );
};

export default RecipientsConfirmation;
