import { getConfig } from "config";
import useGetLocker from "hooks/useGetLocker";
import { useCallback } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DeliverySVG } from "./order_delivered.svg";

interface Props {
  disabled?: boolean;
}

function Providers({ disabled }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lockerQuery = useGetLocker();

  const getRoute = (data: any) => {
    return data?.config?.deposit_code
      ? "/recipients-password"
      : data?.config?.enforce_carrier_validation
        ? "/carrier-validation"
        : "/select-recipient";
  };

  const redirectToRecipients = useCallback(async () => {
    let result = lockerQuery;
    if (!lockerQuery.data) result = await lockerQuery.refetch();
    if (!result.data) return;

    navigate(getRoute(result.data));
  }, [navigate, lockerQuery]);

  const { app } = getConfig();
  const publicUrl = new URL(
    lockerQuery.data?.public_url || "https://expedibox.com"
  );

  return (
    <>
      {app === "expedilock" ? (
        <div className="text-center mt-4">
          <QRCode
            bgColor="#FFF8F8"
            fgColor="#0d99d6"
            value={lockerQuery.data?.public_url || ""}
          />

          <div className="mt-4 mb-3 lead">{t("home.newDetails")}</div>

          <h1 className="fw-light fs-2 text-primary">
            <u>
              {publicUrl.hostname}
              <strong>{publicUrl.pathname}</strong>
            </u>
          </h1>
        </div>
      ) : (
        <div className="d-grid gap-2 mx-auto w-75">
          <DeliverySVG className="m-auto" style={{ width: 200, height: 200 }} />
          <Button
            disabled={lockerQuery.isInitialLoading || disabled}
            className="fs-3 mt-3 rounded-pill shadow"
            onPointerUp={redirectToRecipients}>
            {lockerQuery.isInitialLoading ? (
              <Spinner animation="border" />
            ) : (
              t("home.drop")
            )}

            {!lockerQuery.isInitialLoading &&
              lockerQuery?.data?.config?.deposit_code && (
                <i className="bi-lock-fill ms-1"></i>
              )}
          </Button>
          {disabled && (
            <p className="text-muted text-center">{t("home.dropHelp")}</p>
          )}
        </div>
      )}
    </>
  );
}

export default Providers;
