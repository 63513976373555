import { useNavigate, useLocation } from "react-router-dom";
import useIdleTimer from "../../hooks/useIdleTimer";

export const IDLE_TIME_MS = 60 * 1000;

export function useRedirectHomeOnIdle() {
  const navigate = useNavigate();
  const location = useLocation();
  useIdleTimer(IDLE_TIME_MS, () => {
    if (location.pathname !== "/") navigate("/");
  });
}
