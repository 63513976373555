import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { getConfig } from "../config";

async function createBulkReservation(data: BulkReservation) {
    const { apiUrl, apiKey, lockerId } = getConfig();
    const url = `${apiUrl}/reservationsBulk`;
    const headers = {
      "X-Locker-Private-Key": apiKey,
      "Content-Type": "application/json",
    };

    const response = await fetch(url, {
      headers,
      method: "POST",
      body: JSON.stringify({ ...data, locker_id: lockerId  }),
    });

    if (!response.ok) {
      throw new Error("Network Error");
    }

    return response.json();
};

export default function useCreateBulkReservation(options?: UseMutationOptions<unknown, unknown, BulkReservation, unknown>) {
  return useMutation({
    mutationFn: (data: any) => createBulkReservation(data),
    ...options,
  });
}
