import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { getConfig } from "../config";

async function updateReservation(data: Reservation): Promise<Reservation> {
  const { apiUrl, apiKey, lockerId } = getConfig();
  data.locker_id = lockerId;
  const url = `${apiUrl}/reservations/${data.id}/`;
  const headers = {
    "X-Locker-Private-Key": apiKey,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    headers,
    method: "PATCH",
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useUpdateReservation(options?: UseMutationOptions<unknown, unknown, Reservation, unknown>) {
  return useMutation({
    ...options,
    mutationFn: (data: Reservation) => updateReservation(data),
  });
}
