const RESERVATION_CACHE_KEY = "_RESERVATION_CACHE";
const EXPIRATION_TIME = 15 * 60 * 1000; // 15 minutes

const useReservationCache = () => {
  const item = localStorage.getItem(RESERVATION_CACHE_KEY);
  const now = new Date().getTime();
  const _data: Reservation[] = item ? JSON.parse(item) : [];
  const data = _data.filter(
    (r) =>
      r.pickup_at && now - new Date(r.pickup_at).getTime() < EXPIRATION_TIME
  );

  const clear = () => localStorage.removeItem(RESERVATION_CACHE_KEY);

  const add = (reservation: Reservation) => {
    if (data.find((r) => r.id === reservation.id)) return;
    reservation.pickup_at = new Date();
    data.push(reservation);
    localStorage.setItem(RESERVATION_CACHE_KEY, JSON.stringify(data.slice(-5)));
  };

  return { add, data, clear };
};

export default useReservationCache;
