import { useEffect, useState } from "react";
import { getLocker } from "./useGetLocker";

export default function useMaintenance() {
  const [maintenance, setMaintenance] = useState(false);
  const [offline, setOffline] = useState(false);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const locker = await getLocker();
        setMaintenance(!locker.enabled);
        // Post a message to the service worker
        navigator?.serviceWorker?.controller?.postMessage({
          type: "REPLAY_QUEUE",
        });
        setOffline(!!locker?.offline);
      } catch (e) {
        setOffline(true);
      }
    }, 120 * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { maintenance, offline };
}
