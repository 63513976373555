type NavigatorWithConnection = Navigator & {
    connection?: {
        downlink: number;
    };
}

export const getUserIPAddress = async (): Promise<string | null> => {
    const api_url = "https://api.ipify.org/?format=json";

    try {
      const response = await fetch(api_url);
      const { ip } = await response.json();
      return ip;
    } catch (error) {
      console.error(error);
      return null;
    }
}

export const getDownlinkSpeedInMbps = (): number | null => {
    const navigatorWithConnection = navigator as NavigatorWithConnection;

    if (navigatorWithConnection.connection && navigatorWithConnection.connection.downlink) {
        return navigatorWithConnection.connection.downlink;
    }

    return null;
}
