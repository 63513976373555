import { useEffect } from "react";
import useGetReservations from "./useGetReservations";

const FETCH_INTERVAL = 3 * 60 * 60 * 1000; // 3 hours

export const useSyncReservationsCache = () => {
  const reservationQuery = useGetReservations(
    { active: true },
    { enabled: false }
  );
  useEffect(() => {
    const fetchList = async () => {
      const lastFetch = Number(
        localStorage.getItem("reservationListLastFetch")
      );

      if (!lastFetch || Date.now() - lastFetch > FETCH_INTERVAL) {
        reservationQuery.refetch();
        localStorage.setItem("reservationListLastFetch", Date.now().toString());
      }
    };

    fetchList(); // Run immediately on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array means run once on mount.
};
