import { useTranslation } from "react-i18next";
import useGetLocker from "../../hooks/useGetLocker";
import { useEffect, useRef } from "react";

export function useDefaultLanguage() {
  const { i18n } = useTranslation();
  const lockerQuery = useGetLocker();
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (
      !hasFetchedData.current &&
      lockerQuery.data &&
      i18n.language !== lockerQuery.data.config.language
    ) {
      i18n.changeLanguage(lockerQuery.data.config.language);
      hasFetchedData.current = true;
    }
  }, [lockerQuery.data, i18n]);
}
