import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCamera } from "components/Camera";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { PageTitle } from "components/PageTitle";
import { useToast } from "components/Toast";
import useCreateReservation from "hooks/useCreateReservation";
import useGetLocker from "hooks/useGetLocker";
import useOnTimeout from "hooks/useOnTimeout";
import useOpenDoor from "hooks/useOpenDoor";
import useReservationCache from "hooks/useReservationCache";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Door } from "./Door";
import { Placeholders } from "./Placeholders";

const DOOR_SIZES: { [key: string]: number } = {
  small: 0,
  medium: 10,
  large: 20,
  "x-large": 30,
  "xx-large": 40,
  "large-refrigere": 50,
  "x-large congelée": 60,
  room: 70,
};

export default function SelectDoor() {
  const { t, i18n } = useTranslation();
  const { warn } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const lockerQuery = useGetLocker();
  const openDoor = useOpenDoor();
  const counter = useOnTimeout(45, () => navigate("/"));
  const reservationCache = useReservationCache();
  const availableDoors = lockerQuery.data?.doors?.filter(
    (door) => door.allow_reservations
  ) as Door[];
  const [isRedirected, setRedirected] = useState(false);
  const { state } = useLocation();
  const { recipient, responses, trackingNumber } = state;
  const name = `${recipient?.first_name} ${recipient?.last_name}`;
  const camera = useCamera();

  const createReservation = useCreateReservation({
    onSuccess: (data) => {
      const reservation = data as Reservation;
      if (reservation && reservation.door && reservation.door.position) {
        queryClient.invalidateQueries({ queryKey: ["locker"] });
        openDoor.mutate(reservation.door.position);
        reservationCache.clear();
        navigate("/deposit-success", { state: { recipient, reservation } });
      } else {
        Sentry.captureMessage("No reservation returned from createReservation");
        warn();
      }
    },
  });

  const doors = useMemo(() => {
    return availableDoors?.reduce<{ [key: string]: Door[] }>((prev, curr) => {
      if (!curr.allow_multiple_reservations) {
        if (!(curr.dimension in prev)) {
          prev[curr.dimension] = [];
        }

        if (curr.status === "ready") {
          prev[curr.dimension].push(curr);
        }
      }
      return prev;
    }, {});
  }, [availableDoors]);

  const capture = useCallback(() => {
    if (!lockerQuery.data?.config.webcam_enabled) return;
    return camera?.capture() || undefined;
  }, [camera, lockerQuery.data?.config.webcam_enabled]);

  const createReservationMutate = createReservation.mutate;
  const createReservationHandler = useCallback(
    (key: string) => {
      createReservationMutate({
        first_name: recipient.first_name,
        last_name: recipient.last_name,
        email: recipient.email,
        cellphone_number: recipient.mobile_number,
        landline_number: recipient.landline_number,
        unit: recipient.unit || undefined,
        recipient_id: recipient.id,
        door_position: doors[key][0].position,
        deposit: true,
        deposit_photo: capture(),
        deposit_answers: responses || undefined,
        tracking_number: trackingNumber || undefined,
      });
    },
    [
      recipient,
      doors,
      createReservationMutate,
      responses,
      capture,
      trackingNumber,
    ]
  );

  useEffect(() => {
    if (
      availableDoors.length === 1 &&
      availableDoors[0].allow_multiple_reservations &&
      !isRedirected
    ) {
      navigate("/recipients-confirmation", {
        state: {
          recipients: [{ ...recipient, tracking_number: trackingNumber }],
          door_position: availableDoors[0].position,
          next: "/select-recipient",
        },
      });
      setRedirected(true);
    }
  }, [availableDoors, isRedirected, navigate, recipient, trackingNumber]);

  useEffect(() => {
    if (camera?.mediaError) {
      warn(camera.mediaError.toString());
    }
  }, [camera, warn]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0 overflow-hidden">
      <Header title={t("doors.title")} />

      <PageTitle>
        {createReservation.isLoading ? (
          <Spinner className="me-2" animation="border" />
        ) : (
          <i className="bi-box-seam me-2"></i>
        )}
        {t("doors.package")}
        {"  "} &nbsp;
        <strong
          style={{ backgroundColor: "#eff6ff", fontWeight: 700 }}
          className="h3  text-primary rounded p-2 px-3 fs-2">
          {recipient.unit ? `${recipient.unit} - ` : null}
          {recipient.id
            ? name
            : `${recipient.mobile_number} - ${recipient.first_name ?? " "} `}{" "}
        </strong>
      </PageTitle>

      <Container className="mt-5">
        {lockerQuery.isInitialLoading && <Placeholders />}

        {recipient && !lockerQuery.isInitialLoading && (
          <Row className="gy-5">
            {Object.keys(doors)
              .sort((a, b) => DOOR_SIZES[a] - DOOR_SIZES[b])
              .map((key) => (
                <Col key={key} md="4">
                  <Door
                    title={`${t([`common.${key}`, key])} (${
                      doors[key].length
                    })`}
                    disabled={
                      !doors[key].length || createReservation.isLoading
                    }>
                    <div className="d-grid shadow-sm">
                      <Button
                        disabled={
                          !doors[key].length || createReservation.isLoading
                        }
                        size="lg"
                        onPointerUp={() => createReservationHandler(key)}
                        className="rounded-pill">
                        {doors[key].length ? (
                          <>
                            {t("doors.open")}&nbsp;
                            <i className="bi bi-arrow-right ms-1"></i>
                          </>
                        ) : (
                          t("doors.unavailable")
                        )}
                      </Button>
                    </div>
                  </Door>
                </Col>
              ))}

            {lockerQuery.data &&
              lockerQuery.data.doors.map((door: Door) => {
                return (
                  door.allow_reservations &&
                  door.allow_multiple_reservations && (
                    <Col key={door.position} md="4">
                      <Door
                        title={
                          i18n.exists(`common.${door.dimension}.overflow`)
                            ? `${t(`common.${door.dimension}.overflow`)}`
                            : `${door.dimension} (${t("common.overflow")})`
                        }>
                        <div className="d-grid shadow-sm">
                          <Button
                            size="lg"
                            className="rounded-pill"
                            onPointerUp={() =>
                              navigate("/recipients-confirmation", {
                                state: {
                                  recipients: [
                                    {
                                      ...recipient,
                                      tracking_number:
                                        trackingNumber || undefined,
                                    },
                                  ],
                                  door_position: door.position,
                                  responses,
                                },
                              })
                            }>
                            <>
                              {t("doors.select")}&nbsp;
                              <i className="bi bi-arrow-right ms-1"></i>
                            </>
                          </Button>
                        </div>
                      </Door>
                    </Col>
                  )
                );
              })}
          </Row>
        )}
      </Container>
      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate(-1)}>
            <i className="bi-arrow-left-circle-fill me-2"></i>
            {t("common.back")} ({counter})
          </Button>
        }
      />
    </div>
  );
}
