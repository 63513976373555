import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getConfig } from "../../config";
import useGetLocker from "../../hooks/useGetLocker";
import useOnTimeout from "../../hooks/useOnTimeout";
import expediboxBg from "./expedibox-bg.jpg";
import expediboxLogo from "./expedibox-logo.png";
import parcelOneBg from "./parcelone-background.jpg";
import parcelOneLogo from "./parcelone-logo.png";

const companies = {
  expedibox: {
    name: "Expedibox",
    logo: expediboxLogo,
    bg: expediboxBg,
  },
  parcelone: {
    name: "Parcel One",
    logo: parcelOneLogo,
    bg: parcelOneBg,
  },
};

function Help() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [clicks, setClicks] = useState(0);
  const counter = useOnTimeout(45, () => navigate("/"));
  const { branding } = getConfig();
  const lockerQuery = useGetLocker({ refetchOnMount: false });

  useEffect(() => {
    if (clicks >= 3) {
      window.location.reload();
    }
  }, [clicks]);

  const company = companies[branding as keyof typeof companies];

  return (
    <div className="container-fluid h-100 position-absolute top-0 left-0">
      <div className="row h-100">
        <div className="col-7 p-5 h-100">
          <div className="mb-2 align-items-center">
            <img
              className=""
              src={company.logo}
              alt="logo"
              style={{ width: "200px" }}
            />
            <div className="text-muted ms-1 mt-3">
              {branding === "parcelone"
                ? t("help.poweredBy", {
                    version: process.env.REACT_APP_VERSION,
                  })
                : t("help.version", { version: process.env.REACT_APP_VERSION })}
            </div>
          </div>

          <h1 className="text-primary display-5 mb-3">
            {t("help.title", { company: company.name })}
          </h1>

          <p className="lead">{t("help.p1", { company: company.name })}</p>

          <p className="lead">{t("help.p2")}</p>

          <hr className="my-5" />

          <p className="lead mb-2">
            {t("help.p3")} <br />
            {t("help.p4")}
          </p>

          <p>
            <i className="bi bi-phone me-2"></i>
            {lockerQuery.data?.config.support_phone}
          </p>

          <p>
            <i className="bi bi-envelope me-2"></i>
            {lockerQuery.data?.config.support_email}
          </p>

          <p>
            <i className="bi bi-globe me-2"></i>
            {lockerQuery.data?.config.support_website}
          </p>

          <button
            className="btn btn-link fs-3 text-decoration-none ps-0 mt-4"
            onPointerUp={() => navigate(-1)}>
            <i className="bi bi-arrow-left-circle me-2"></i>
            {t("help.back")} ({counter})
          </button>
        </div>
        <div
          className="h-100 col-5"
          style={{
            backgroundImage: `url(${company.bg})`,
            backgroundSize: "cover",
          }}
          onPointerUp={() => setClicks(clicks + 1)}></div>
      </div>
    </div>
  );
}

export default Help;
