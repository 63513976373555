import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

export default function LangSwitcher() {
  const { i18n } = useTranslation();
  return (
    <Button
      size="lg"
      variant="outline-light"
      onPointerUp={() =>
        i18n.changeLanguage(i18n.language === "fr" ? "en" : "fr")
      }
    >
      {i18n.language === "fr" ? "EN" : "FR"}
    </Button>
  );
}
