import { useTranslation } from "react-i18next";
import { Header } from "../../components/Header";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useOnTimeout from "../../hooks/useOnTimeout";
import Keyboard, { KeyboardInput, SimpleKeyboard } from "react-simple-keyboard";
import { MutableRefObject, useEffect, useRef, useState } from "react";

import layout from "./layout.json";
import { phoneFormatter } from "./phoneFormatter";

interface RecipientInputs {
  mobile_number?: string;
  first_name?: string;
  email?: string;
}

export default function RecipientForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const keyboard: MutableRefObject<SimpleKeyboard> = useRef(null!);
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [inputs, setInputs] = useState<RecipientInputs>({});
  const [inputName, setInputName] = useState("default");
  const [layoutName, setLayoutName] = useState("default");
  const counter = useOnTimeout(90, () => navigate("/"));

  const buttonTheme = [
    {
      class: phoneValid ? "bg-info text-white" : "disabled-key",
      buttons: "{enter}",
    },
  ];

  if (phoneFocused) {
    buttonTheme.push({
      class: "disabled-key",
      buttons:
        "q w e r t y u i o p a s d f g h j k l z x c v b n m {shift} {space} . ' - ",
    });
  }

  const onChangeAll = (inputs: KeyboardInput) => {
    setInputs({
      ...inputs,
      mobile_number: phoneFormatter(inputs["mobile_number"]),
    });
  };

  useEffect(() => {
    setPhoneValid(inputs.mobile_number?.replace(/\D/g, "").length === 10);
  }, [inputs.mobile_number]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Container
        className="m0 p-0  bg-white border h-100"
        style={{ maxWidth: "925px" }}
      >
        <Header
          title={t("recipientForm.recipientInfo")}
          left={
            <Button
              variant="link"
              className="fs-4 text-decoration-none text-on-surface-variant"
              onPointerUp={() => navigate(-1)}
            >
              <i className="bi-arrow-left-circle-fill me-2"></i>
              {t("common.back")} ({counter})
            </Button>
          }
        />

        <Container className="mt-4 py-4" style={{ maxWidth: "650px" }}>
          <Form>
            <Form.Label className="text-black">
              {t("recipientForm.phoneNumber")}
            </Form.Label>
            <InputGroup className="mb-4">
              <InputGroup.Text>+1</InputGroup.Text>
              <Form.Control
                placeholder="(555) 555-5555"
                autoFocus
                value={inputs.mobile_number || ""}
                onFocus={() => {
                  setInputName("mobile_number");
                  setPhoneFocused(true);
                }}
                readOnly
              />
            </InputGroup>

            <Form.Group className="mb-4" controlId="formBasicPassword">
              <Form.Label className="text-black">
                {t("recipientForm.name")}{" "}
                <span className="text-muted">
                  ({t("recipientForm.optional")})
                </span>
              </Form.Label>
              <Form.Control
                name="first_name"
                value={inputs.first_name || ""}
                onFocus={() => {
                  setInputName("first_name");
                  setPhoneFocused(false);
                }}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
              <Form.Label className="text-black">
                {t("recipientForm.email")}{" "}
                <span className="text-muted">
                  ({t("recipientForm.optional")})
                </span>
              </Form.Label>
              <Form.Control
                name="email"
                value={inputs.email || ""}
                onFocus={() => {
                  setInputName("email");
                  setPhoneFocused(false);
                }}
                readOnly
              />
            </Form.Group>
          </Form>
        </Container>
      </Container>

      <div className="position-absolute bottom-0 w-100">
        <Keyboard
          disableCaretPositioning={true}
          layout={layout}
          layoutName={layoutName}
          keyboardRef={(r) => (keyboard.current = r)}
          inputName={inputName}
          buttonTheme={buttonTheme}
          onChangeAll={onChangeAll}
          onKeyPress={(button: string) => {
            if (button === "{shift}") {
              setLayoutName(layoutName === "default" ? "shift" : "default");
            } else if (button === "{enter}") {
              navigate("/select-door", {
                state: { recipient: inputs },
              });
            }
          }}
          display={{
            "{bksp}": "⌫",
            "{space}": " ",
            "{shift}": "⇧",
            "{enter}": t("recipientForm.enter"),
          }}
        />
      </div>
    </div>
  );
}
