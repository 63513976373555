import { useCallback, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Numpad } from "../../components/Numpad";
import useGetLocker from "../../hooks/useGetLocker";
import useOnTimeout from "../../hooks/useOnTimeout";

export default function RecipientPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const counter = useOnTimeout(45, () => navigate("/"));

  const lockerQuery = useGetLocker({ refetchOnMount: false });

  let onSubmit = useCallback(() => {
    setError(false);
    if (!lockerQuery.data) return;
    if (lockerQuery.data?.config?.deposit_code === parseInt(code)) {
      if (lockerQuery.data?.config?.enforce_carrier_validation) {
        navigate("/carrier-validation");
      } else {
        navigate("/select-recipient");
      }
    } else {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
        setError(true);
      }, 1000);
    }
  }, [code, navigate, lockerQuery.data]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={<i className="bi-lock"></i>} />

      <Container className="mt-4 pt-2">
        <Numpad
          disabled={disabled || lockerQuery.isInitialLoading}
          loading={disabled}
          mask="*"
          className="shadow-sm py-4 rounded bg-surface bg-gradient"
          placeholder={
            error
              ? t("password.invalid") || ""
              : disabled
                ? t("password.check") || ""
                : t("password.code") || ""
          }
          onChange={(code) => setCode(code)}
          onSubmit={onSubmit}
        />
      </Container>
      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate("/")}>
            <i className="bi-arrow-left-circle-fill me-2"></i>
            {t("common.back")} ({counter})
          </Button>
        }
      />
    </div>
  );
}
