import { Button, ButtonProps } from "react-bootstrap";

import "./RoundButton.css";

interface Props extends ButtonProps {
  pulse?: boolean;
}

export default function RoundButton(props: Props) {
  let className = "rounded-circle mx-2 fs-2 shadow";
  const { pulse, ...buttonProps } = props;
  if (pulse) {
    className += " pulse";
  }
  return (
    <Button
      style={{ width: 90, height: 90 }}
      className={className}
      {...buttonProps}
    />
  );
}
