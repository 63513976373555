import { useTranslation } from "react-i18next";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { Code } from "./components/Code";
import Providers from "./Providers";
import { useState } from "react";
import useGetLocker from "../../hooks/useGetLocker";

import logo from "./logo.png";
import pin from "./pin.png";
import { getConfig } from "../../config";
import { OfflineInfo } from "./components/OfflineInfo";
import { useSyncReservationsCache } from "../../hooks/useSyncReservationsCache";

function Title() {
  const { app, branding } = getConfig();
  if (app === "expedilock") return <h1>Expedilock</h1>;
  else if (branding === "parcelone") return <h1>Parcel One</h1>;
  else
    return (
      <img
        className="d-block mx-auto py-2"
        style={{ width: "200px" }}
        src={logo}
        alt="Expedibox"
      />
    );
}

function Head({ offline }: { offline: boolean }) {
  const { t } = useTranslation();

  return (
    <Header titleComponent={<Title />}>
      <p className="lead">{t("home.title")}</p>
      <Container fluid className="mt-3">
        <Row>
          <Col>
            <h1 className="fw-light mb-0">{t("home.code")}</h1>
          </Col>
          <Col>
            {!offline && (
              <h1 className="fw-light mb-0">{t("home.delivery")}</h1>
            )}
          </Col>
        </Row>
      </Container>
    </Header>
  );
}

function Home({ offline }: { offline: boolean }) {
  const [code, setCode] = useState("");
  const lockerQuery = useGetLocker();
  const { t } = useTranslation();

  useSyncReservationsCache();

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Head offline={offline} />

      <Container className="mt-4" fluid>
        <Row>
          <Col>
            <Code onChange={(code) => setCode(code)} />
          </Col>
          <Col className="border-start border-primary">
            {offline ? <OfflineInfo /> : <Providers disabled={!!code} />}
          </Col>
        </Row>
      </Container>

      <Footer
        left={
          <>
            <img
              className="me-1 fs-4"
              style={{ width: "20px" }}
              src={pin}
              alt="pin"
            />
            <span className="text-primary">{lockerQuery.data?.name}</span>
          </>
        }>
        {lockerQuery.data?.test && (
          <Alert variant="warning">
            <i className="bi-exclamation-triangle-fill me-2"></i>
            {t("home.testEnabled")}
          </Alert>
        )}
      </Footer>
    </div>
  );
}

export default Home;
