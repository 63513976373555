import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { totpSecretStore } from "../idb/TOTPSecretStore";

export function useTOTPSecrets(): UseQueryResult<TOTPSecret[], Error> {
  return useQuery<TOTPSecret[], Error>({
    queryKey: ["totpSecrets"],
    queryFn: async (): Promise<TOTPSecret[]> => {
      try {
        const data = await totpSecretStore.getAll();
        return data as TOTPSecret[];
      } catch (error) {
        console.error("Failed to fetch TOTP secrets from IndexedDB:", error);
        return Promise.reject(error);
      }
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    retry: 1,
  });
}
