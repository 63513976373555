import { useMutation, UseMutationOptions } from "@tanstack/react-query"
import { getConfig } from "../config";

async function cancelReservation(id: string) {
  const { apiUrl, apiKey } = getConfig();
  const url = `${apiUrl}/reservations/${id}/cancel`;
  const headers = {
    "X-Locker-Private-Key": apiKey,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    headers,
    method: "POST",
  });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return;
}



export default function useCancelReservation(options?: UseMutationOptions<unknown, unknown, string, unknown>) {
  return useMutation({
    mutationFn: (id: string) => cancelReservation(id),
    ...options,
  });
}
