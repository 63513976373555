import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { getConfig } from "config";
import useGetLocker from "hooks/useGetLocker";
import useOnTimeout from "hooks/useOnTimeout";
import { useCallback, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import DepositImage from "./deposit-illustration.png";
import GreenCheckAnimation from "./GreenCheckAnimation";

export default function DepositSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { app } = getConfig();
  const [counter, setCounter] = useState(8);
  const counterBackHome = useOnTimeout(45, () => navigate("/"));
  const [isTimeout, setIsTimeout] = useState(false);

  const lockerQuery = useGetLocker();

  const recipient = location.state?.recipient;
  const reservation = location.state?.reservation;
  const reservations = location.state?.reservations;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimeout(true);
    }, 8000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (counter > 0) {
      const countdown = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(countdown);
    }
  }, [counter]);

  const redirectToRecipients = useCallback(async () => {
    let result = lockerQuery;
    if (!lockerQuery?.data) result = await lockerQuery.refetch();

    if (!result?.data) return;

    if (result?.data?.config?.enforce_carrier_validation)
      return navigate("/carrier-validation");
    return navigate("/select-recipient");
  }, [navigate, lockerQuery]);

  if (!reservation && !reservations) {
    // sometimes a render is triggered without a reservation and I'm too dumb to figure out why
    // but it seems only happening when changing routes, so we'll just skip de the render for now to fix this issue
    // and I'll leave this note for future me who I hope would be smarter
    return <></>;
  }

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header
        title={
          isTimeout ? t("depositSuccess.title") : t("depositProgress.title")
        }
      />

      <Container className="p-3" style={{ marginTop: 80, width: "80%" }}>
        {/* IF EXPEDILOCK */}
        {app === "expedilock" && reservation && (
          <div className="border-start border-3 rounded border-primary bg-surface text-on-surface text-center shadow p-3">
            <div className="d-flex align-items-center">
              {app === "expedilock" && reservation && (
                <div className="border-end border-1 border-dark p-5">
                  <h1 className="display-5">
                    {t("common.door")} {reservation.door?.position}
                  </h1>
                </div>
              )}

              <div className="flex-grow-1">
                <div className="display-5 fw-light mb-2">
                  {t("depositSuccess.thanks")}&nbsp;
                  <i className="bi bi-patch-check ms-2 text-primary"></i>
                </div>

                <p className="fw-light fs-4">{t("depositSuccess.subtitle")}</p>
              </div>
            </div>
          </div>
        )}
        {/* If RECIPIENT/IF LOCKER */}
        {recipient && reservation && reservation?.id && (
          <section
            className="d-flex align-items-center justify-content-center"
            style={{ gap: "210px" }}>
            <div className="d-flex flex-column align-items-center">
              <div
                className="  p-2 rounded-pill"
                style={{
                  backgroundColor: isTimeout ? "" : "#dbeafe",
                  width: "340px",
                  height: "340px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {isTimeout ? (
                  <GreenCheckAnimation />
                ) : (
                  <img
                    src={DepositImage}
                    alt="Illustration of the locker"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
              <span
                className="lead fw-normal mt-3 text-center fs-3"
                style={{ width: "380px", height: "90px", textAlign: "center" }}>
                {isTimeout
                  ? t("depositSuccess.subtitle")
                  : t("depositProgress.subtitle")}
              </span>
            </div>
            <div className="d-flex flex-column gap-5 ">
              {((recipient && reservation && reservation.id) ||
                reservations) && (
                <Button
                  onPointerUp={() => redirectToRecipients()}
                  size="lg"
                  className="rounded-pill shadow-sm bg-gradient fs-2"
                  style={{ width: "400px" }}
                  disabled={!isTimeout}>
                  <i className="bi bi-plus-circle-fill me-2"></i>
                  {t("depositSuccess.newDeposit")}
                  {counter > 0 && ` (${counter})`}
                </Button>
              )}

              {recipient && reservation && reservation?.id && (
                <Button
                  size="lg"
                  className="rounded-pill shadow-sm bg-white border-danger fs-2 text-danger"
                  onPointerUp={() =>
                    navigate("/cancel-reservation", { state: { reservation } })
                  }>
                  <i className="bi bi-x-circle me-2"></i>
                  {t("cancelReservation.subtitle")}
                </Button>
              )}
            </div>
          </section>
        )}
      </Container>

      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate("/")}>
            <i className="bi-house-door-fill me-2"></i>
            {t("common.home")} ({counterBackHome})
          </Button>
        }
      />
    </div>
  );
}
