import { Button, Container, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import useCancelReservation from "../../hooks/useCancelReservation";
import useOnTimeout from "../../hooks/useOnTimeout";

function CancelReservation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const reservation = location.state?.reservation;
  const counter = useOnTimeout(45, () => navigate("/"));

  const cancelReservation = useCancelReservation({
    onSuccess: () => navigate("/"),
  });

  if (!reservation || !reservation.id) {
    // sometimes a render is triggered without a reservation and I'm too dumb to figure out why
    // but it seems only happening when changing routes, so we'll just skip de the render for now to fix this issue
    // and I'll leave this note for future me who I hope would be smarter
    return <></>;
  }

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("cancelReservation.title")} />

      <Container className="mt-5 p-3 w-75">
        <div className="mt-5 border-start border-5 border-danger rounded bg-surface text-on-surface text-center shadow px-5 py-4">
          <div className="display-6 fw-light mb-3">
            <i className="bi bi-exclamation-triangle-fill me-2 text-danger"></i>{" "}
            {t("cancelReservation.subtitle")}
          </div>

          <hr className="my-4" />

          <div className="">
            <Button
              size="lg"
              variant="outline-primary"
              className="rounded-pill me-5"
              onPointerUp={() => navigate("/")}>
              {t("cancelReservation.cancel")}
            </Button>
            <Button
              size="lg"
              variant="danger"
              className="rounded-pill shadow-sm px-5"
              disabled={cancelReservation.isLoading}
              onPointerUp={() => {
                if (reservation.id) {
                  cancelReservation.mutate(reservation.id);
                }
              }}>
              {cancelReservation.isLoading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                t("cancelReservation.confirm")
              )}
            </Button>
          </div>
        </div>
      </Container>

      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate("/")}>
            <i className="bi-house-door-fill me-2"></i>
            {t("common.home")} ({counter})
          </Button>
        }
      />
    </div>
  );
}

export default CancelReservation;
