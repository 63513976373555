import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import * as OTPAuth from "otpauth";
import { useCallback } from "react";
import { getConfig } from "../config";
import { totpSecretStore } from "../idb/TOTPSecretStore";

export async function getLocker(): Promise<Locker> {
  const { apiUrl, apiKey, lockerId } = getConfig();
  const url = `${apiUrl}/lockers/${lockerId}/`;
  const totpHeader = generateHeader(apiKey);
  const headers = {
    "X-Locker-Private-Key": apiKey,
    "x-otp-code": totpHeader,
  };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Error fetching locker: " + response?.statusText);
  }
  return response.json();
}

export default function useGetLocker(
  options?: UseQueryOptions<unknown, unknown, Locker, Array<string>> & {
    skipIndexedDB?: boolean;
  }
) {
  const { lockerId } = getConfig();
  const queryClient = useQueryClient();

  const addToIndexedDB = useCallback(
    async (data: TOTPSecret[]) => {
      try {
        await totpSecretStore.clear();
        await totpSecretStore.putAll(data);
        queryClient.invalidateQueries(["totpSecrets"]);
      } catch (error) {
        console.error("Failed to add TOTP secrets to IndexedDB:", error);
      }
    },
    [queryClient]
  );

  return useQuery({
    queryFn: () => getLocker(),
    queryKey: ["locker", lockerId],
    placeholderData: {
      config: {
        language: "fr",
        support_phone: "1-833-622-0304",
        support_email: "info@expedibox.com",
        support_website: "support.expedibox.com",
      },
    },
    ...options,
    onSuccess: async (data) => {
      if (
        !options?.skipIndexedDB &&
        Array.isArray(data?.totp_secrets) &&
        data.totp_secrets.length > 0
      ) {
        await addToIndexedDB(data.totp_secrets);
      }
      options?.onSuccess?.(data);
    },
  });
}

const generateHeader = (apiKey: string) => {
  const totpSecret = OTPAuth.Secret.fromUTF8(apiKey);
  const totp = new OTPAuth.TOTP({
    issuer: "Locker",
    label: "OTPSecretAccess",
    algorithm: "SHA1",
    digits: 10,
    period: 30,
    secret: totpSecret,
  });
  return totp.generate();
};
