export const phoneFormatter = (input: string) => {
  const cleaned = ("" + input).replace(/\D/g, "");

  const match = cleaned.match(/(\d{1,3})(\d{0,3})(\d{0,})$/);
  if (!match) return input;

  const formatted = [
    match[1].length ? `(${match[1]}` : "",
    match[1].length === 3 ? ") " : "",
    match[2],
    match[3] ? "-" : "",
    match[3],
  ].join("");

  return formatted;
};
