import { Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Header } from "../../components/Header";
import useOnTimeout from "../../hooks/useOnTimeout";

function GlobalError() {
  const { t } = useTranslation();
  const refreshPage = () => (window.location.href = "/");
  const counter = useOnTimeout(120, refreshPage);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("globalError.pageTitle")} />

      <Container className="text-center p-3" style={{ marginTop: 100 }}>
        <div className="border-start border-3 rounded border-primary bg-surface text-on-surface text-center shadow px-5 py-4">
          <div className="display-4 fw-light mb-2">
            {t("globalError.title")}
          </div>

          <div className="lead fw-light mb-5">{t("globalError.subtitle")}</div>

          <Button size="lg" className="rounded-pill" onPointerUp={refreshPage}>
            <i className="bi-arrow-clockwise"></i> {t("globalError.refresh")} (
            <code className="text-on-surface-variant">{counter}</code>)
          </Button>
        </div>

        <div className="mt-5">
          <i className="bi bi-phone me-2"></i>
          1-833-622-0304
          <i className="bi bi-dot mx-2"></i>
          <i className="bi bi-envelope me-2"></i>
          info@expedibox.com
          <i className="bi bi-dot mx-2"></i>
          <i className="bi bi-globe me-2"></i>
          support.expedibox.com
        </div>
      </Container>
    </div>
  );
}

export default GlobalError;
