import { UseMutationOptions, useMutation } from "@tanstack/react-query";

async function openDoor(position: string) {
  const x = position[0];
  const y = position.substring(1);
  const url = `http://127.0.0.1:5000/?xpos=${x}&ypos=${y}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return;
}

export default function useOpenDoor(options?: UseMutationOptions<unknown, unknown, string, unknown>) {
  return useMutation({
    ...options,
    mutationFn: (position: string) => openDoor(position),
  });
}
