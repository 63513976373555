export function initConfig(key: string, hint?: string, prompt = true) {
  const urlParams = new URLSearchParams(window.location.search);
  // first, look in the URL
  let value = urlParams.get(key);

  if (!value) {
    // second, look in local storage
    value = localStorage.getItem(key);
  }

  if (!value && prompt && process.env.NODE_ENV !== "test") {
    // third, prompt the user
    value = window.prompt(key, hint);
  }

  if (value) {
    localStorage.setItem(key, value);
  } else if (process.env.NODE_ENV === "development") {
    console.error(`${key} is null`);
  }
}

export function getConfig() {
  const app = localStorage.getItem("app") || "locker";
  const lockerId = localStorage.getItem("lockerId") || "";
  const apiKey = localStorage.getItem("apiKey") || "";
  const apiUrl = localStorage.getItem("apiUrl") || "";
  const wsUrl = localStorage.getItem("wsUrl") || "";
  const branding = localStorage.getItem("branding") || "expedibox";
  return { lockerId, apiKey, apiUrl, wsUrl, app, branding };
}

/**
 * Quick Configuration
 */

if (window.location.search.includes("?test")) {
  localStorage.setItem("apiKey", "0ab81908-bad1-461e-adcd-a599d871f7a2");
  localStorage.setItem("lockerId", "MmD8W26G");
  localStorage.setItem("apiUrl", "https://api.expedibox.com");
  localStorage.setItem("wsUrl", "wss://35.203.109.132");
} else if (window.location.search.includes("?dev")) {
  localStorage.setItem("apiKey", "4b2971c3-a587-4709-8e56-b7a656d2b2c1");
  localStorage.setItem("lockerId", "7WJz7GqZ");
  localStorage.setItem("apiUrl", "http://api.expediboxdev.com:8000");
  localStorage.setItem("wsUrl", "ws://127.0.0.1:8000");
} else {
  initConfig("lockerId");
  initConfig("apiKey");
  initConfig("apiUrl", "http://api.expediboxdev.com:8000");
  initConfig("wsUrl", "ws://127.0.0.1:8000");
  initConfig("app", "locker", false);
  initConfig("branding", "expedibox", false);
}
