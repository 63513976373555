import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import { useTranslation } from "react-i18next";
import { Footer } from "components/Footer";
import { Numpad } from "components/Numpad";
import RoundButton from "components/RoundButton";
import useGetRecipients from "hooks/useGetRecipients";
import RecipientList from "./RecipientList";
import { Header } from "components/Header";
import useGetLocker from "hooks/useGetLocker";
import { sortRecipients } from "helpers/SortRecipientsHelper";

import "react-simple-keyboard/build/css/index.css";
import useOnTimeout from "hooks/useOnTimeout";

const RECIPIENT_DATA_STALE_TIME = 3 * 3600 * 1000; // 3 hours
const RECIPIENT_DATA_THRESHOLD = 500;
const RECIPIENT_DATA_MAX_OVER_TRESHOLD = 35;

export const layout = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "Q W E R T Y U I O P -",
    "A S D F G H J K L '",
    "Z X C V B N M , . {shift}",
    "{space}",
  ],
};

export default function SelectRecipient() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [showNumpad, setShowNumpad] = useState(true);
  const counter = useOnTimeout(45, () => navigate("/"));
  const { state } = useLocation();

  const recipientsQuery = useGetRecipients({
    refetchOnMount: true,
    staleTime: RECIPIENT_DATA_STALE_TIME,
  });

  const lockerQuery = useGetLocker({ refetchOnMount: false });

  useEffect(() => {
    if (lockerQuery.data && lockerQuery.data.config.default_to_keyboard) {
      setShowNumpad(false);
    }
  }, [lockerQuery.data]);

  const recipients = useMemo(() => {
    if (!recipientsQuery.data) return [];
    let data = sortRecipients(recipientsQuery.data, filter, showNumpad);

    if (data.length >= RECIPIENT_DATA_THRESHOLD)
      data = data.slice(0, RECIPIENT_DATA_MAX_OVER_TRESHOLD);
    return data;
  }, [recipientsQuery.data, filter, showNumpad]);

  const selectRecipientHandler = (recipient: Recipient) => {
    if (recipient.deposit_questions.length > 0) {
      navigate("/deposit-form", {
        state: { ...state, recipient, form_index: 0, responses: [] },
      });
    } else {
      navigate("/select-door", { state: { ...state, recipient } });
    }
  };

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("recipients.title")} />

      <Container className="mt-4 pt-2">
        <Row className="align-items-center gy-3">
          <Col>
            <div className="shadow rounded bg-surface text-on-surface">
              <div
                className="fs-4 fw-light rounded-top"
                style={{
                  minHeight: showNumpad ? 500 : 250,
                  maxHeight: showNumpad ? 500 : 250,
                  overflow: "overlay",
                }}>
                <RecipientList
                  recipients={recipients}
                  loading={recipientsQuery.isInitialLoading}
                  showEmail={lockerQuery.data?.config.show_member_emails}
                  onSelect={(recipient: Recipient) =>
                    selectRecipientHandler(recipient)
                  }
                />
              </div>
            </div>
          </Col>

          {!lockerQuery.isInitialLoading && showNumpad && (
            <Col md="5">
              <Numpad
                disabled={recipientsQuery.isInitialLoading}
                className="shadow py-4 rounded bg-surface bg-gradient"
                placeholder={t("recipients.unit") || ""}
                onChange={(code) => setFilter(code)}
                extra={
                  <RoundButton
                    variant="secondary"
                    disabled={recipientsQuery.isInitialLoading}
                    onPointerUp={() => {
                      setShowNumpad(false);
                      setFilter("");
                    }}>
                    ABC
                  </RoundButton>
                }
              />
            </Col>
          )}

          {lockerQuery.data?.config.allow_deposit_without_member &&
            showNumpad && (
              <div
                className="d-flex align-items-center mx-auto bg-white text-black rounded-pill shadow pe-0 ps-4"
                style={{ width: 700, marginTop: "35px" }}
                onPointerUp={() => navigate("/recipient-form")}>
                <div className="flex-grow-1 fs-5">
                  {t("recipients.notInList")}
                </div>
                <div>
                  <Button
                    size="lg"
                    className="rounded-pill"
                    style={{ height: 60 }}>
                    <i className="bi bi-keyboard me-2"></i>
                    {t("recipients.addManually")}
                  </Button>
                </div>
              </div>
            )}

          {!showNumpad && (
            <Col md="12">
              <div className="p-2 shadow rounded bg-surface bg-gradient">
                <input
                  type="text"
                  className="form-control form-control-lg mb-2"
                  placeholder={t("recipients.filter") || ""}
                  readOnly
                  value={filter}
                />
                <Keyboard
                  layout={layout}
                  onChange={(code: string) => setFilter(code)}
                  display={{ "{shift}": "123" }}
                  mergeDisplay={true}
                  onKeyPress={(button: string) => {
                    if (button === "{shift}") {
                      setShowNumpad(true);
                      setFilter("");
                    }
                  }}
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate("/")}>
            <i className="bi-arrow-left-circle-fill me-2"></i>
            {t("common.back")} ({counter})
          </Button>
        }
      />
    </div>
  );
}
