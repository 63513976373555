import { Numpad } from "components/Numpad";
import { useToast } from "components/Toast";
import useGetLocker from "hooks/useGetLocker";

import * as Sentry from "@sentry/react";
import { getReservations } from "hooks/useGetReservations";
import useOpenDoor from "hooks/useOpenDoor";
import useReservationCache from "hooks/useReservationCache";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useVerifyCode } from "./VerifyCode";
import { useTOTPSecrets } from "hooks/useTOTPSecrets";
import { useQueryClient } from "@tanstack/react-query";

const DEPOSIT = "deposit";
const WITHDRAW = "withdraw";

interface Props {
  onChange?: (code: string) => any;
}

export const useHandleSubmit = (
  verifyCode: (code: string) => Promise<void>
) => {
  const queryClient = useQueryClient();
  const { warn } = useToast();

  const handleSubmit = useCallback(
    async (inputCode: string) => {
      try {
        // Check if the TOTP secrets are already in the cache
        let totpSecrets = queryClient.getQueryData(["totpSecrets"]);

        if (!totpSecrets) {
          try {
            // If not in cache, attempt to fetch the data
            totpSecrets = await queryClient.fetchQuery({
              queryKey: ["totpSecrets"],
              retry: 1, // Only retry once to avoid long delays
              retryDelay: 500,
            });
          } catch (fetchError) {
            console.warn("Failed to fetch TOTP secrets:", fetchError);
            // Log the error but don't throw, allowing the flow to continue
          }
        }

        // Proceed with verification, regardless of whether TOTP secrets were successfully fetched
        await verifyCode(inputCode);
      } catch (error) {
        console.error("Error during code verification:", error);
        warn();
      }
    },
    [queryClient, verifyCode, warn]
  );

  return handleSubmit;
};

const useReservationHandler = () => {
  const navigate = useNavigate();
  const { warn } = useToast();
  const reservationCache = useReservationCache();

  const handleReservation = useCallback(
    async (code: number) => {
      try {
        const data = await getReservations({
          code: code.toString(),
          active: true,
        });

        if (!data || !data.count) {
          navigate("invalid-code", { state: { code } });
          return;
        }

        const reservation: Reservation = data.results[0];
        const action =
          code === reservation.deposit_code
            ? DEPOSIT
            : code === reservation.pickup_code
              ? WITHDRAW
              : null;

        if (
          !action ||
          (action === DEPOSIT && reservation.deposit_at) ||
          (action === WITHDRAW && !reservation.deposit_at)
        ) {
          navigate("invalid-code", { state: { code } });
          return;
        }

        if (action === DEPOSIT) reservationCache.clear();
        if (action === WITHDRAW && !reservation.multiple_withdrawals_allowed)
          reservationCache.add(reservation);
        navigate("code-progress", { state: { reservation, action } });
      } catch (error) {
        console.error("Error fetching reservation:", error);
        warn();
        Sentry.captureException(error);
      }
    },
    [navigate, warn, reservationCache]
  );

  return handleReservation;
};

export default function Code({ onChange }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { data: totpSecrets } = useTOTPSecrets();
  const handleReservation = useReservationHandler();

  const verifyCodeDependencies = useMemo(
    () => ({
      useGetLocker,
      useOpenDoor,
      useReservationCache,
    }),
    []
  );

  const verifyCode = useVerifyCode(
    {
      totpSecrets,
      setTriggerReservationQuery: handleReservation,
    },
    verifyCodeDependencies
  );

  const handleSubmit = useHandleSubmit(verifyCode);

  const onSubmit = useCallback(
    async (inputCode: string) => {
      setIsLoading(true);
      try {
        await handleSubmit(inputCode);
      } catch (error) {
        console.error("Error during code verification:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleSubmit]
  );

  return (
    <Numpad
      className="shadow-sm py-4 rounded bg-surface bg-gradient"
      disabled={isLoading}
      loading={isLoading}
      onSubmit={onSubmit}
      onChange={onChange}
    />
  );
}
