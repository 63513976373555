import { useState } from "react";
import { ListGroup, Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { DoorStuckHelpModal } from "./DoorStuckHelpModal";
import useOnTimeout from "../../hooks/useOnTimeout";
import { getConfig } from "../../config";

export default function WithdrawSuccess() {
  const { t } = useTranslation();
  const { app } = getConfig();
  const [doorStuckShow, setDoorStuckShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const counter = useOnTimeout(45, () => navigate("/"));
  const reservation = location.state?.reservation;

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("withdrawSuccess.title")} />

      <Container className="mt-5 p-3" style={{ width: "80%" }}>
        <div className="mt-5 border-start border-3 rounded border-primary bg-surface text-on-surface text-center shadow p-3">
          <div className="d-flex align-items-center">
            {app === "expedilock" && reservation && (
              <div className="border-end border-1 border-dark p-5">
                <h1 className="display-5">
                  {t("common.door")} {reservation.door?.position}
                </h1>
              </div>
            )}
            <div className="flex-grow-1">
              <div className="display-4 fw-light mb-2">
                {t("withdrawSuccess.thanks")}{" "}
                <i className="bi bi-patch-check ms-2 text-primary"></i>
              </div>

              <p className="fw-light fs-4">{t("withdrawSuccess.subtitle")}</p>
            </div>
          </div>
        </div>
      </Container>

      {reservation && (
        <Container className="fs-4 w-75" style={{ marginTop: 100 }}>
          <ListGroup>
            <ListGroup.Item
              action
              className="fw-light d-flex"
              onPointerUp={() => setDoorStuckShow(true)}
            >
              <div className="me-auto">{t("faq.doorStuck.title")}</div>
              <i className="bi-arrow-right"></i>
            </ListGroup.Item>
          </ListGroup>
        </Container>
      )}

      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate("/")}
          >
            <i className="bi-house-door-fill me-2"></i>
            {t("common.home")} ({counter})
          </Button>
        }
      />

      {reservation && (
        <DoorStuckHelpModal
          show={doorStuckShow}
          onHide={() => setDoorStuckShow(false)}
          door={reservation.door?.position}
        />
      )}
    </div>
  );
}
