import * as OTPAuth from "otpauth";

export const validateTOTP = (
  code: string,
  secrets: TOTPSecret[] | []
): TOTPSecret | null => {
  for (const secret of secrets) {
    const totp = new OTPAuth.TOTP({
      secret: OTPAuth.Secret.fromBase32(secret.secret),
      digits: secret.digits || 8,
      period: secret.period || 180,
    });

    if (totp.validate({ token: code, window: 1 }) !== null) {
      return secret;
    }
  }

  return null;
};

export const generateTOTP = (secret: TOTPSecret): string => {
  const totp = new OTPAuth.TOTP({
    secret: OTPAuth.Secret.fromBase32(secret.secret),
    digits: secret.digits || 8,
    period: secret.period || 180,
  });

  return totp.generate();
};

export const getRemainingSeconds = (secret: TOTPSecret): number => {
  const period = secret.period || 180;
  return period - (Math.floor(Date.now() / 1000) % period);
};

export const useTOTPValidation = (secrets: TOTPSecret[]) => {
  const validate = (code: string) => {
    return validateTOTP(code, secrets);
  };

  return { validate, generateTOTP, getRemainingSeconds };
};
