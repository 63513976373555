import { t } from "i18next";

export const OfflineInfo: React.FC = () => {
  return (
    <div className="gap-2 mx-auto my-auto d-flex flex-column justify-content-center w-75 h-100">
      <div className="display-5 fw-light mb-2 text-center">
        <i className="bi bi-wifi-off ms-2 text-danger align-middle"></i>
        <span className="fw-normal fs-4 align-middle ms-2">
          {t("offline.searchingNetwork")}
        </span>
      </div>
      <div className="display-5 text-center fw-light fs-4">
        <p>{t("offline.description")}</p>
        <p>{t("offline.widthdraw")}</p>
      </div>
    </div>
  );
};
