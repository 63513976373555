import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getConfig } from "../../config";
import translation from "../../i18n";
import expedilock from "../../i18n/expedilock";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translation.en },
    fr: { translation: translation.fr },
  },
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

if (getConfig().app === "expedilock") {
  i18n.addResourceBundle("en", "translation", expedilock.en, true, true);
  i18n.addResourceBundle("fr", "translation", expedilock.fr, true, true);
}
