const DbParams = {
  Version: 5,
  Name: "LockerDB",
};

export enum Stores {
  ActiveReservations = "ActiveReservations",
  Locker = "Locker",
  TOTPSecrets = "TOTPSecrets",
}

export enum Indexes {
  PickupCode = "pickupCode",
  DepositCode = "depositCode",
  TOTPSecret = "totpSecret",
}

class IndexedDBStore {
  storeName: Stores;

  constructor(storeName: Stores) {
    this.storeName = storeName;
  }

  private async openDB() {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = indexedDB.open(DbParams.Name, DbParams.Version);
      request.onupgradeneeded = function () {
        let db = request.result;
        if (!db.objectStoreNames.contains(Stores.ActiveReservations)) {
          const activeReservationsStore = db.createObjectStore(
            Stores.ActiveReservations,
            {
              keyPath: "id",
            }
          );
          activeReservationsStore.createIndex(
            Indexes.PickupCode,
            "pickup_code",
            {
              unique: true,
            }
          );
          activeReservationsStore.createIndex(
            Indexes.DepositCode,
            "deposit_code",
            {
              unique: true,
            }
          );
        }

        if (!db.objectStoreNames.contains(Stores.Locker)) {
          db.createObjectStore(Stores.Locker, { keyPath: "id" });
        }

        if (!db.objectStoreNames.contains(Stores.TOTPSecrets)) {
          const totpSecretsStore = db.createObjectStore(Stores.TOTPSecrets, {
            keyPath: "name",
          });
          totpSecretsStore.createIndex(Indexes.TOTPSecret, "name", {
            unique: true,
          });
        }
      };

      request.onsuccess = function () {
        resolve(request.result);
      };
      request.onerror = function () {
        reject(request.error);
      };
    });
  }

  private async getObjectStore(mode: IDBTransactionMode) {
    const db = await this.openDB();
    const tx = db.transaction(this.storeName, mode);
    return tx.objectStore(this.storeName);
  }

  private async operateOnStore(
    mode: IDBTransactionMode,
    operation: string,
    item?: any | IDBValidKey | IDBKeyRange,
    indexName?: Indexes
  ) {
    const store = await this.getObjectStore(mode);
    let request: IDBRequest;

    switch (operation) {
      case "get":
        request = store.get(item as IDBValidKey | IDBKeyRange);
        break;
      case "getByIndex":
        if (!indexName)
          throw new Error("indexName is required for getByIndex operation");
        request = store.index(indexName).get(item as IDBValidKey | IDBKeyRange);
        break;
      case "getAll":
        request = store.getAll();
        break;
      case "put":
        request = store.put(item);
        break;
      case "delete":
        request = store.delete(item as IDBValidKey | IDBKeyRange);
        break;
      case "clear":
        request = store.clear();
        break;
      default:
        throw new Error("Invalid operation on store");
    }

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  async get(id: IDBValidKey | IDBKeyRange) {
    return this.operateOnStore("readonly", "get", id);
  }

  async getByIndex(indexName: Indexes, key: IDBValidKey | IDBKeyRange) {
    return this.operateOnStore("readonly", "getByIndex", key, indexName);
  }

  async getAll() {
    return this.operateOnStore("readonly", "getAll");
  }

  async put(item: any) {
    return this.operateOnStore("readwrite", "put", item);
  }

  async putAll(items: any[]) {
    const promises = items.map((item) =>
      this.operateOnStore("readwrite", "put", item)
    );
    return Promise.all(promises);
  }

  async delete(id: IDBValidKey | IDBKeyRange) {
    return this.operateOnStore("readwrite", "delete", id);
  }

  async clear() {
    return this.operateOnStore("readwrite", "clear");
  }
}

export { IndexedDBStore };
