import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getConfig } from "../config";

async function getRecipients(): Promise<Recipient[]> {
  const { apiUrl, apiKey } = getConfig();
  const url = `${apiUrl}/recipients/`;
  const headers = { "X-Locker-Private-Key": apiKey };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useGetRecipients(options?: UseQueryOptions<unknown, unknown, Recipient[], Array<string>>) {
  return useQuery({
    queryFn: () => getRecipients(),
    queryKey: ["recipients"],
    ...options,
  });
}
