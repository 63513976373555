import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Keyboard from "react-simple-keyboard";

const layout = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "q w e r t y u i o p -",
    "a s d f g h j k l {enter}",
    "{shift} z x c v b n m . '",
    "{space}",
  ],
  shift: [
    "! @ # $ % ^ & * ( ) {bksp}",
    "Q W E R T Y U I O P -",
    "A S D F G H J K L {enter}",
    "{shift} Z X C V B N M . '",
    "{space}",
  ],
};

const DepositForm = () => {
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const location = useLocation();
  const [layoutName, setLayoutName] = useState("default");
  const { t } = useTranslation();
  const { recipient, form_index, responses, trackingNumber } = location.state;
  const form: DepositQuestion = recipient.deposit_questions[form_index];
  const isEmpty = text.trim() === "";

  const submitResponseHandler = () => {
    responses.push({ question: form.question, answer: text });
    if (recipient.deposit_questions.length > form_index + 1) {
      return navigate("/deposit-form", {
        state: {
          recipient,
          form_index: form_index + 1,
          responses,
          trackingNumber: trackingNumber || undefined,
        },
      });
    }

    navigate("/select-door", {
      state: {
        recipient,
        responses,
        trackingNumber: trackingNumber || undefined,
      },
    });
  };

  return (
    <div className="position-absolute h-100 w-100 bg-on-background-variant">
      <div className="bg-white position-absolute w-100" style={{ top: "15%" }}>
        <Container
          className="py-3 bg-surface-variant shadow text-on-surface-variant text-center"
          style={{ minHeight: 80 }}
          fluid>
          <div className="d-flex align-items-center justify-content-between">
            <Button onPointerUp={() => navigate(-1)}>
              <i className="bi bi-arrow-left" style={{ fontSize: 32 }}></i>
            </Button>
            <h1 className="fw-light mb-0">{form.question}</h1>
            <Button onPointerUp={() => navigate("/help")}>
              <i className="bi bi-question-circle" style={{ fontSize: 32 }}></i>
            </Button>
          </div>
        </Container>

        <Container className="mt-4 text-center text-on-background">
          <h1 className="fw-light text-black fs-3">{form.help_text}</h1>
        </Container>

        <Form className="my-4 w-100 px-5">
          <Form.Control
            type="text"
            className="form-control"
            required={form.required}
            value={text}
            readOnly
          />
        </Form>
        <Keyboard
          layout={layout}
          layoutName={layoutName}
          onChange={(text) => setText(text)}
          mergeDisplay={true}
          display={{
            "{enter}": t("depositForm.enter"),
            "{shift}": t("depositForm.shift"),
            "{bksp}": t("depositForm.backspace"),
            "{space}": t("depositForm.space"),
          }}
          buttonTheme={[
            {
              class:
                (form.required && !isEmpty) ||
                (!form.required && isEmpty) ||
                (!form.required && !isEmpty)
                  ? "bg-info text-white"
                  : "disabled-key",
              buttons: "{enter}",
            },
          ]}
          onKeyPress={(button) => {
            if (button === "{shift}") {
              setLayoutName(layoutName === "default" ? "shift" : "default");
            }
            if (button === "{enter}") {
              submitResponseHandler();
            }
          }}
        />
      </div>
    </div>
  );
};

export default DepositForm;
