export const sortRecipients = (
  recipients: Recipient[],
  searchTerm: string,
  sortByUnitNumber: boolean
): Recipient[] => {
  // Normalize the text and remove diacritics and make it uppercase
  // Diacritics are the accents and other marks (eg. é, è, ê, ë, etc.)
  // https://stackoverflow.com/a/37511463
  const removeDiacritics = (text: string): string =>
    text
      ?.normalize("NFD")
      ?.replace(/\p{Diacritic}/gu, "")
      ?.toUpperCase(); // Make the string uppercase to make the search case-insensitive

  const formatUnit = (text: string): string =>
    text
      ?.replace(/(\d+)-(\d+)/g, "$1.$2") // Replace hyphens with dots (eg. 1-2 -> 1.2)
      ?.replace(/[^\d.]/g, ""); // Remove all non-digit characters except dots (eg. A101.1 -> 101.1)

  const createSearchableString = (recipient: Recipient): string =>
    removeDiacritics(
      `${recipient?.unit || ""} ${recipient?.first_name || ""} ${
        recipient?.last_name || ""
      }`
    );

  const calculateVisibilityPriority = (visibility: string): number =>
    visibility === "PRIORITY" ? 0 : 1;

  // Precompute the searchable string, the visibility priority, and the unit number
  // to avoid recomputing them at each comparison
  const precomputed = recipients
    .map((recipient) => ({
      recipient,
      searchableString: createSearchableString(recipient),
      visibilityPriority: calculateVisibilityPriority(
        recipient?.visibility || "NORMAL"
      ),
      unitNumber: sortByUnitNumber
        ? parseFloat(formatUnit(recipient?.unit)) || Infinity
        : Infinity,
    }))
    .filter(({ searchableString }) =>
      searchableString.includes(searchTerm.toUpperCase())
    );

  return (
    precomputed
      .sort((a, b) => {
        // If the visibility priority is different, sort by it
        if (a.visibilityPriority !== b.visibilityPriority) {
          return a.visibilityPriority - b.visibilityPriority;
        }
        // If the unit number is different, sort by it
        if (sortByUnitNumber && a.unitNumber !== b.unitNumber) {
          return a.unitNumber - b.unitNumber;
        }
        // Else, sort by the full string
        return a.searchableString.localeCompare(b.searchableString);
      })
      // Return the sorted recipients
      .map(({ recipient }) => recipient)
  );
};
