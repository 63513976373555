import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useState,
} from "react";
import Webcam from "react-webcam";
import useGetLocker from "../../hooks/useGetLocker";

type MediaError = string | DOMException | undefined;

interface ContextProps {
  capture: () => string | null | undefined;
  mediaError: MediaError;
}

const Ctx = createContext<ContextProps | null>(null);

export function CameraProvider({ children }: any) {
  const webcamRef = createRef<Webcam & HTMLVideoElement>();
  const [mediaError, setMediaError] = useState<MediaError>();
  const lockerQuery = useGetLocker();

  const capture = useCallback(() => {
    return webcamRef.current?.getScreenshot();
  }, [webcamRef]);

  return (
    <Ctx.Provider value={{ capture, mediaError }}>
      {children}

      {lockerQuery.data?.config.webcam_enabled && (
        <Webcam
          muted={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{ position: "absolute", right: "100%" }}
          onUserMediaError={(error) => setMediaError(error)}
        />
      )}
    </Ctx.Provider>
  );
}

export const useCamera = () => useContext(Ctx);
