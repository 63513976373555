import { useState } from "react";
import { Button, Container, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import useOnTimeout from "../../hooks/useOnTimeout";
import { ConfirmAddressHelpModal } from "./ConfirmAddressHelpModal";

export default function InvalidCode() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [confirmAddressShow, setConfirmAddressShow] = useState(false);
  const counter = useOnTimeout(45, () => navigate("/"));

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("invalidCode.title")} />

      <Container className="mt-5 p-3 w-75">
        <div className="mt-5 border-start border-3 rounded border-danger bg-surface text-on-surface text-center shadow px-5 py-4">
          <div className="display-4 fw-light mb-2">
            <i className="bi bi-exclamation-triangle-fill ms-2 text-danger"></i>
          </div>

          <p className="fw-light fs-4">{t("invalidCode.subtitle")}</p>
        </div>
      </Container>

      <Container className="fs-4 w-75" style={{ marginTop: 100 }}>
        <ListGroup>
          <ListGroup.Item
            action
            className="fw-light d-flex"
            onPointerUp={() => setConfirmAddressShow(true)}
          >
            <div className="me-auto">{t("faq.confirmAddress.title")}</div>
            <i className="bi-arrow-right"></i>
          </ListGroup.Item>
        </ListGroup>
      </Container>

      <ConfirmAddressHelpModal
        show={confirmAddressShow}
        onHide={() => setConfirmAddressShow(false)}
        code={location.state?.code}
      />

      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate("/")}
          >
            <i className="bi-house-door-fill me-2"></i>
            {t("common.home")} ({counter})
          </Button>
        }
      />
    </div>
  );
}
