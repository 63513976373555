import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Router from "./Router";
import useSunCycleTheme from "../../hooks/useSunCycleTheme";
import { MemoryRouter, BrowserRouter } from "react-router-dom";
import { ToastProvider, useToast } from "../Toast";
import * as Sentry from "@sentry/react";
import "./i18n";

import "./app.scss";
import "./dark.scss";
import { CameraProvider } from "../Camera/Camera";

function App() {
  let RouterProvider = MemoryRouter;
  if (process.env.NODE_ENV === "development") RouterProvider = BrowserRouter;

  useSunCycleTheme();

  const { warn } = useToast();

  return (
    <ToastProvider>
      <QueryClientProvider
        client={
          new QueryClient({
            defaultOptions: {
              queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                networkMode: "always",
                onError(err) {
                  console.error(err);
                  Sentry.captureException(err);
                  warn();
                },
              },
              mutations: {
                networkMode: "always",
                onError(error, variables, context) {
                  console.error(error);
                  Sentry.captureException(error);
                  warn();
                },
              },
            },
          })
        }>
        <CameraProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <RouterProvider>
            <Router />
          </RouterProvider>
        </CameraProvider>
      </QueryClientProvider>
    </ToastProvider>
  );
}

export default App;
