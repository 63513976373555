import { useEffect } from "react";
import suncalc from "suncalc";

// Montreal
const LAT = 45.508888;
const LONG = -73.561668;

function light() {
  const el = document.documentElement;
  el.style.setProperty("--bs-body-bg", "var(--bs-gray-100)");
  el.style.setProperty("--bs-body-color", "var(--bs-gray-900)");
  el.classList.add("light");
  el.classList.remove("dark");
}

function dark() {
  const el = document.documentElement;
  el.style.setProperty("--bs-body-bg", "var(--bs-gray-900)");
  el.style.setProperty("--bs-body-color", "var(--bs-gray-100)");
  el.classList.add("dark");
  el.classList.remove("light");
}

function applyTheme() {
  const now = new Date();
  const times = suncalc.getTimes(now, LAT, LONG);

  if (now > times.sunrise && now < times.sunset) {
    light();
  } else {
    dark();
  }
}

export default function useSunCycleTheme() {
  useEffect(() => {
    applyTheme();
    const interval = setInterval(() => {
      applyTheme();
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, []);
}
