import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import useOnTimeout from "../../hooks/useOnTimeout";

export default function CodeSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const counter = useOnTimeout(45, () => navigate("/"));

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("codeSuccess.title")} />

      <Container className="p-3 w-75" style={{ marginTop: 100 }}>
        <div className="border-start border-3 rounded border-primary bg-surface text-on-surface text-center shadow px-5 py-4">
          <div className="display-4 fw-light mb-2">
            {t("codeSuccess.thanks")}{" "}
            <i className="bi bi-patch-check ms-2 text-primary"></i>
          </div>

          <p className="fw-light fs-4">{t("codeSuccess.subtitle")}</p>
        </div>
      </Container>

      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate("/")}
          >
            <i className="bi-house-door-fill me-2"></i>
            {t("common.home")} ({counter})
          </Button>
        }
      />
    </div>
  );
}
