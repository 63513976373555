import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Button, Container, Form, ListGroup, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { PageTitle } from "components/PageTitle";
import useOnTimeout from "hooks/useOnTimeout";
import CarrierValidationModal from "./CarrierValidationModal";

export enum ModalTopics {
  UnableToScan,
  HowToScan,
  ContactSupport,
}

const CarrierValidation = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const counter = useOnTimeout(45, () => navigate("/"));

  const [trackingNumber, setTrackingNumber] = useState("");
  const [invalidTrackingNumber, setInvalidTrackingNumber] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTopic, setModalTopic] = useState<ModalTopics>(0);

  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input field
    focusInput();
  }, [trackingNumber, invalidTrackingNumber]);

  const focusInput = () => {
    if (inputRef.current) {
      (inputRef.current as any).focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // The scanner sends an "Enter" key after scanning the barcode
    const isEnterKey = e?.key === "Enter";

    if (isEnterKey) {
      validateCarrier();
    } else if (invalidTrackingNumber) {
      setTrackingNumber("");
      setInvalidTrackingNumber(false);
    }
  };

  const validateCarrier = () => {
    if (trackingNumber.length >= 7) {
      setInvalidTrackingNumber(false);
      handleValidCarrier();
    } else if (trackingNumber.length > 0) {
      setInvalidTrackingNumber(true);
    }
  };

  const handleValidCarrier = () => {
    navigate("/select-recipient", {
      state: { ...state, trackingNumber },
    });
  };

  const handleModal = (topic: ModalTopics) => {
    setModalTopic(topic);
    setShowModal(true);
  };

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0 overflow-hidden">
      <Header title={t("carrierValidation.pageTitle")} />

      <Container className="h-100">
        <div
          className={`border-start border-3 rounded bg-surface text-on-surface text-center shadow py-4 w-75 mx-auto my-5 ${
            invalidTrackingNumber ? "border-danger" : ""
          }`}>
          <PageTitle>
            <i className="bi-upc-scan me-2" />
            {t("carrierValidation.title")}
          </PageTitle>
          <Row className="px-3 w-50 mx-auto mb-2">
            <Form.Control
              type="text"
              size="lg"
              className={`rounded-pill text-center mx-auto fs-4 shadow-sm ${
                invalidTrackingNumber ? "border-danger text-danger" : ""
              }`}
              value={trackingNumber}
              ref={inputRef}
              placeholder={"RA123456789CA"}
              onKeyDown={handleKeyDown}
              onChange={(e) => setTrackingNumber(e.target.value)}
              style={{ caretColor: "transparent" }}
              onBlur={focusInput}
            />
          </Row>

          {invalidTrackingNumber && (
            <Form.Text className="text-danger fs-5">
              {t("carrierValidation.errorTitle")}
              <br />
              {t("carrierValidation.errorHint")}
            </Form.Text>
          )}
        </div>
        <ListGroup className="w-75 fs-4 mx-auto">
          <ListGroup.Item
            action
            className="fw-light d-flex"
            onPointerUp={() => handleModal(ModalTopics.HowToScan)}>
            <i className="bi-question-circle-fill me-2 link-primary"></i>
            <div className="me-auto">
              {t("carrierValidation.faq.howToScan")}
            </div>
            <i className="bi-arrow-right"></i>
          </ListGroup.Item>
          <ListGroup.Item
            action
            className="fw-light d-flex"
            onPointerUp={() => handleModal(ModalTopics.UnableToScan)}>
            <i className="bi-exclamation-circle-fill me-2 link-primary"></i>
            <div className="me-auto">
              {t("carrierValidation.faq.unableToScan")}
            </div>
            <i className="bi-arrow-right"></i>
          </ListGroup.Item>
          <ListGroup.Item
            action
            className="fw-light d-flex"
            onPointerUp={() => handleModal(ModalTopics.ContactSupport)}>
            <i className="bi-chat-dots-fill me-2 link-primary"></i>
            <div className="me-auto">
              {t("carrierValidation.faq.contactSupport")}
            </div>
            <i className="bi-arrow-right"></i>
          </ListGroup.Item>
        </ListGroup>
      </Container>

      <CarrierValidationModal
        topic={modalTopic}
        show={showModal}
        onHide={() => setShowModal(false)}
      />

      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate(-1)}>
            <i className="bi-arrow-left-circle-fill me-2"></i>
            {t("common.back")} ({counter})
          </Button>
        }
      />
    </div>
  );
};

export default CarrierValidation;
