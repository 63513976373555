import { UseQueryResult } from "@tanstack/react-query";
import useGetLocker from "hooks/useGetLocker";
import { t } from "i18next";
import { Modal } from "react-bootstrap";
import { ModalTopics } from "./CarrierValidation";
import labelExample from "./labelExample.jpeg";

const CarrierValidationModal = ({ topic, show, onHide }: Props) => {
  const lockerQuery = useGetLocker({ refetchOnMount: false });

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="bi-question-circle me-2"></i>
          {topic === ModalTopics.UnableToScan &&
            t("carrierValidation.modal.unableToScan.title")}
          {topic === ModalTopics.HowToScan &&
            t("carrierValidation.modal.howToScan.title")}
          {topic === ModalTopics.ContactSupport &&
            t("carrierValidation.modal.contactSupport.title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {topic === ModalTopics.UnableToScan && (
          <>
            <p className="lead">
              {t("carrierValidation.modal.unableToScan.body.lead")}
            </p>

            <p>
              &bull; {t("carrierValidation.modal.unableToScan.body.reason1")}
              <br />
              &bull; {t("carrierValidation.modal.unableToScan.body.reason2")}
              <br />
              &bull; {t("carrierValidation.modal.unableToScan.body.reason3")}
              <br />
            </p>

            <p className="lead mb-2 mt-4">
              {t("carrierValidation.modal.unableToScan.body.contactSupport")}
            </p>
            {supportSection(lockerQuery)}
          </>
        )}

        {topic === ModalTopics.HowToScan && (
          <>
            <p>{t("carrierValidation.modal.howToScan.body1")}</p>
            <p>{t("carrierValidation.modal.howToScan.body2")}</p>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <img
                src={labelExample}
                alt="how to scan"
                className="w-50 mx-auto"
              />
            </div>
          </>
        )}

        {topic === ModalTopics.ContactSupport && (
          <>
            <p className="lead mb-2">
              {t("carrierValidation.modal.contactSupport.body1")}
            </p>
            {supportSection(lockerQuery)}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

const supportSection = (lockerQuery: UseQueryResult<Locker, unknown>) => {
  return (
    <>
      <p>
        <i className="bi bi-phone me-2"></i>
        {lockerQuery.data?.config.support_phone}
      </p>
      <p>
        <i className="bi bi-envelope me-2"></i>
        {lockerQuery.data?.config.support_email}
      </p>
      <p>
        <i className="bi bi-globe me-2"></i>
        {lockerQuery.data?.config.support_website}
      </p>
    </>
  );
};

export interface Props {
  topic: ModalTopics;
  show: boolean;
  onHide: () => any;
}

export default CarrierValidationModal;
